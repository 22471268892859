<template>
  <div class="bb-onboarding" v-if="listAvailable">
    <div class="bg-cercles bb-onboarding__container bb-onboarding__container--guest">
      <main class="og3 ogsteps-container">
        <div class="og3__container">
          <h1 class="og3__title">¡Empieza a regalar!</h1>
          <div class="og3__subtitle">
            Hay algunas cosas de la lista de {{ myList.ownerName }} que le van a ir especialmente bien.
          </div>
          <div class="og3__msg">¿Empezamos por ellas?</div>
          <aside class="og3__actions">
            <div class="nav-onboarding-guest">
              <div class="nav-onboarding-guest__item">
                <router-link :to="{ name: 'OnboardingGuestStep2', params: { id: myList.id } }" class="nav-onboarding-guest__arrow">
                  <img src="../../assets/img/ico/ico-arrow-slick-left.svg" alt="" />
                </router-link>
              </div>
              <div class="nav-onboarding-guest__button">
                <router-link :to="{ name: 'guest', params: { id: myList.id }, hash: '#products_starting' }" class="button button--primary">
                  ¡Vamos!
                </router-link>
              </div>
            </div>
          </aside>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CanGetGuestList from "../../Mixins/CanGetGuestList";
import GuestListFirstVisit from "../mixins/GuestListFirstVisit";

export default {
  name: "OnboardingGuestStep3",
  mixins: [
    CanGetGuestList,
    GuestListFirstVisit,
  ],
  computed: {
    ...mapGetters({
      myList: "myList",
    }),
  },
  mounted() {
    this.setAlreadyVisitedGuestList(true);
  },
};
</script>

<style lang="scss" scoped></style>
